<template>
  <div id="nav" class="nav-bar p-d-flex">
    <div class="nav-box" @click="$router.push('/')">
      About
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
}
</script>
